import type { Filter } from './analytics'

export enum LegalReportType {
  EGAPRO_INDEX = 'EGAPRO_INDEX',
  BDESE = 'BDESE'
}

export interface LegalReport {
  id: string
  year: number
  type: LegalReportType
  createdAt: Date
  releasedAt: Date
  filters: Filter[]
}

export interface LegalReportConfig {
  name: string
  slug: string
  isCreationEnabled: boolean
}
